import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _32bad662 = () => interopDefault(import('../sites/dmx/pages/user/activate.vue' /* webpackChunkName: "sites/dmx/pages/user/activate" */))
const _48d2d3cf = () => interopDefault(import('../sites/dmx/pages/articleList/index.vue' /* webpackChunkName: "sites/dmx/pages/articleList/index" */))
const _51d1d96a = () => interopDefault(import('../sites/dmx/pages/index.vue' /* webpackChunkName: "sites/dmx/pages/index" */))
const _c824787a = () => interopDefault(import('../sites/dmx/pages/search/index.vue' /* webpackChunkName: "sites/dmx/pages/search/index" */))
const _cf51cb8a = () => interopDefault(import('../sites/dmx/pages/contact/index.vue' /* webpackChunkName: "sites/dmx/pages/contact/index" */))
const _01c9fde1 = () => interopDefault(import('../sites/dmx/pages/checkout/index.vue' /* webpackChunkName: "sites/dmx/pages/checkout/index" */))
const _1b797c81 = () => interopDefault(import('../sites/dmx/pages/login.vue' /* webpackChunkName: "sites/dmx/pages/login" */))
const _065e7e8a = () => interopDefault(import('../sites/dmx/pages/register.vue' /* webpackChunkName: "sites/dmx/pages/register" */))
const _c0c329e8 = () => interopDefault(import('../sites/dmx/pages/passwordReset.vue' /* webpackChunkName: "sites/dmx/pages/passwordReset" */))
const _e7d8eb62 = () => interopDefault(import('../sites/dmx/pages/sitemapconfig.vue' /* webpackChunkName: "sites/dmx/pages/sitemapconfig" */))
const _5e8149aa = () => interopDefault(import('../sites/dmx/pages/maintenance.vue' /* webpackChunkName: "sites/dmx/pages/maintenance" */))
const _72750bb4 = () => interopDefault(import('../sites/dmx/pages/user/index.vue' /* webpackChunkName: "sites/dmx/pages/user/index" */))
const _15680852 = () => interopDefault(import('../sites/dmx/pages/passwordForgotten.vue' /* webpackChunkName: "sites/dmx/pages/passwordForgotten" */))
const _5b4f2c22 = () => interopDefault(import('../sites/dmx/pages/checkout/info.vue' /* webpackChunkName: "sites/dmx/pages/checkout/info" */))
const _37c5719a = () => interopDefault(import('../sites/dmx/pages/checkout/finished.vue' /* webpackChunkName: "sites/dmx/pages/checkout/finished" */))
const _6fedaf01 = () => interopDefault(import('../sites/dmx/pages/checkout/methods.vue' /* webpackChunkName: "sites/dmx/pages/checkout/methods" */))
const _aafd290a = () => interopDefault(import('../sites/dmx/pages/checkout/importOrder/index.vue' /* webpackChunkName: "sites/dmx/pages/checkout/importOrder/index" */))
const _3e7b7ca4 = () => interopDefault(import('../sites/dmx/pages/user/shoppingLists/index.vue' /* webpackChunkName: "sites/dmx/pages/user/shoppingLists/index" */))
const _9de326f8 = () => interopDefault(import('../sites/dmx/pages/user/orders/index.vue' /* webpackChunkName: "sites/dmx/pages/user/orders/index" */))
const _4db263ff = () => interopDefault(import('../sites/dmx/pages/user/settings.vue' /* webpackChunkName: "sites/dmx/pages/user/settings" */))
const _65904ce8 = () => interopDefault(import('../sites/dmx/pages/user/dashboard.vue' /* webpackChunkName: "sites/dmx/pages/user/dashboard" */))
const _3d334b06 = () => interopDefault(import('../sites/dmx/pages/user/addressList.vue' /* webpackChunkName: "sites/dmx/pages/user/addressList" */))
const _4fa56677 = () => interopDefault(import('../sites/dmx/pages/user/password.vue' /* webpackChunkName: "sites/dmx/pages/user/password" */))
const _462545ef = () => interopDefault(import('../sites/dmx/pages/checkout/payment/callback.vue' /* webpackChunkName: "sites/dmx/pages/checkout/payment/callback" */))
const _0f95bfec = () => interopDefault(import('../sites/dmx/pages/user/orders/_id.vue' /* webpackChunkName: "sites/dmx/pages/user/orders/_id" */))
const _816f4fe8 = () => interopDefault(import('../sites/dmx/pages/user/shoppingLists/_id.vue' /* webpackChunkName: "sites/dmx/pages/user/shoppingLists/_id" */))
const _697d8e49 = () => interopDefault(import('../sites/dmx/pages/article/_slug.vue' /* webpackChunkName: "sites/dmx/pages/article/_slug" */))
const _471c3287 = () => interopDefault(import('../sites/dmx/pages/articleList/_slug.vue' /* webpackChunkName: "sites/dmx/pages/articleList/_slug" */))
const _1649aaa2 = () => interopDefault(import('../sites/dmx/pages/page/_slug.vue' /* webpackChunkName: "sites/dmx/pages/page/_slug" */))
const _3231ab02 = () => interopDefault(import('../sites/dmx/pages/product/_slug.vue' /* webpackChunkName: "sites/dmx/pages/product/_slug" */))
const _876cbb12 = () => interopDefault(import('../sites/dmx/pages/_.vue' /* webpackChunkName: "sites/dmx/pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/aktivovat",
    component: _32bad662,
    name: "user-activate___cs"
  }, {
    path: "/clanky",
    component: _48d2d3cf,
    name: "articleList___cs"
  }, {
    path: "/en",
    component: _51d1d96a,
    name: "index___en"
  }, {
    path: "/hledat",
    component: _c824787a,
    name: "search___cs"
  }, {
    path: "/kontakt",
    component: _cf51cb8a,
    name: "contact___cs"
  }, {
    path: "/kosik",
    component: _01c9fde1,
    name: "checkout___cs"
  }, {
    path: "/prihlasit",
    component: _1b797c81,
    name: "login___cs"
  }, {
    path: "/registrovat",
    component: _065e7e8a,
    name: "register___cs"
  }, {
    path: "/resetovat-heslo",
    component: _c0c329e8,
    name: "passwordReset___cs"
  }, {
    path: "/sitemapconfig",
    component: _e7d8eb62,
    name: "sitemapconfig___cs"
  }, {
    path: "/udrzba",
    component: _5e8149aa,
    name: "maintenance___cs"
  }, {
    path: "/uzivatel",
    component: _72750bb4,
    name: "user___cs"
  }, {
    path: "/zapomenute-heslo",
    component: _15680852,
    name: "passwordForgotten___cs"
  }, {
    path: "/en/activate",
    component: _32bad662,
    name: "user-activate___en"
  }, {
    path: "/en/articles",
    component: _48d2d3cf,
    name: "articleList___en"
  }, {
    path: "/en/cart",
    component: _01c9fde1,
    name: "checkout___en"
  }, {
    path: "/en/contact",
    component: _cf51cb8a,
    name: "contact___en"
  }, {
    path: "/en/forgotten-password",
    component: _15680852,
    name: "passwordForgotten___en"
  }, {
    path: "/en/login",
    component: _1b797c81,
    name: "login___en"
  }, {
    path: "/en/maintenance",
    component: _5e8149aa,
    name: "maintenance___en"
  }, {
    path: "/en/register",
    component: _065e7e8a,
    name: "register___en"
  }, {
    path: "/en/reset-password",
    component: _c0c329e8,
    name: "passwordReset___en"
  }, {
    path: "/en/search",
    component: _c824787a,
    name: "search___en"
  }, {
    path: "/en/sitemapconfig",
    component: _e7d8eb62,
    name: "sitemapconfig___en"
  }, {
    path: "/en/user",
    component: _72750bb4,
    name: "user___en"
  }, {
    path: "/kosik/dodaci-udaje",
    component: _5b4f2c22,
    name: "checkout-info___cs"
  }, {
    path: "/kosik/dokonceni",
    component: _37c5719a,
    name: "checkout-finished___cs"
  }, {
    path: "/kosik/doprava-a-platba",
    component: _6fedaf01,
    name: "checkout-methods___cs"
  }, {
    path: "/kosik/import-objednavky",
    component: _aafd290a,
    name: "checkout-importOrder___cs"
  }, {
    path: "/uzivatel/nakupni-seznamy",
    component: _3e7b7ca4,
    name: "user-shoppingLists___cs"
  }, {
    path: "/uzivatel/objednavky",
    component: _9de326f8,
    name: "user-orders___cs"
  }, {
    path: "/uzivatel/osobni-udaje",
    component: _4db263ff,
    name: "user-settings___cs"
  }, {
    path: "/uzivatel/prehled",
    component: _65904ce8,
    name: "user-dashboard___cs"
  }, {
    path: "/uzivatel/seznam-adres",
    component: _3d334b06,
    name: "user-addressList___cs"
  }, {
    path: "/uzivatel/zmena-hesla",
    component: _4fa56677,
    name: "user-password___cs"
  }, {
    path: "/checkout/payment/callback",
    component: _462545ef,
    name: "checkout-payment-callback___cs"
  }, {
    path: "/en/cart/delivery-details",
    component: _5b4f2c22,
    name: "checkout-info___en"
  }, {
    path: "/en/cart/finish",
    component: _37c5719a,
    name: "checkout-finished___en"
  }, {
    path: "/en/cart/import-order",
    component: _aafd290a,
    name: "checkout-importOrder___en"
  }, {
    path: "/en/cart/shipping-and-payment",
    component: _6fedaf01,
    name: "checkout-methods___en"
  }, {
    path: "/en/user/address-list",
    component: _3d334b06,
    name: "user-addressList___en"
  }, {
    path: "/en/user/change-password",
    component: _4fa56677,
    name: "user-password___en"
  }, {
    path: "/en/user/orders",
    component: _9de326f8,
    name: "user-orders___en"
  }, {
    path: "/en/user/overview",
    component: _65904ce8,
    name: "user-dashboard___en"
  }, {
    path: "/en/user/settings",
    component: _4db263ff,
    name: "user-settings___en"
  }, {
    path: "/en/user/shopping-lists",
    component: _3e7b7ca4,
    name: "user-shoppingLists___en"
  }, {
    path: "/en/checkout/payment/callback",
    component: _462545ef,
    name: "checkout-payment-callback___en"
  }, {
    path: "/",
    component: _51d1d96a,
    name: "index___cs"
  }, {
    path: "/en/user/orders/:id",
    component: _0f95bfec,
    name: "user-orders-id___en"
  }, {
    path: "/en/user/shopping-lists/:id",
    component: _816f4fe8,
    name: "user-shoppingLists-id___en"
  }, {
    path: "/en/article/:slug",
    component: _697d8e49,
    name: "article-slug___en"
  }, {
    path: "/en/articles/:slug",
    component: _471c3287,
    name: "articleList-slug___en"
  }, {
    path: "/en/page/:slug",
    component: _1649aaa2,
    name: "page-slug___en"
  }, {
    path: "/en/product/:slug",
    component: _3231ab02,
    name: "product-slug___en"
  }, {
    path: "/uzivatel/nakupni-seznamy/:id",
    component: _816f4fe8,
    name: "user-shoppingLists-id___cs"
  }, {
    path: "/uzivatel/objednavky/:id",
    component: _0f95bfec,
    name: "user-orders-id___cs"
  }, {
    path: "/clanek/:slug",
    component: _697d8e49,
    name: "article-slug___cs"
  }, {
    path: "/clanky/:slug",
    component: _471c3287,
    name: "articleList-slug___cs"
  }, {
    path: "/produkt/:slug",
    component: _3231ab02,
    name: "product-slug___cs"
  }, {
    path: "/stranka/:slug",
    component: _1649aaa2,
    name: "page-slug___cs"
  }, {
    path: "/en/*",
    component: _876cbb12,
    name: "all___en"
  }, {
    path: "/*",
    component: _876cbb12,
    name: "all___cs"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
